import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-bootstrap'
import Img from "gatsby-image"


const Page = ({ data }) => {
    const { spaces } = data.mdx.frontmatter

    return (
      <Layout className="bg-green-light">
        <SEO title="Les espaces" />
        <Container className="bg-green-light py-4" fluid>
          <Container>
            {
              spaces.map((space, index) => {
                if (index % 2 === 0) {
                  return (<Row className="align-items-center pt-2">
                    <Col>
                      <Img fluid={space.image.childImageSharp.fluid} alt={space.name}/>
                    </Col>
                    <Col>
                      <h2 className="text-center text-green-dark">{space.name}</h2>
                      <h4 className="text-center text-green-medium">{space.description}</h4>
                    </Col>
                  </Row>)
                } else {
                  return (<Row className="align-items-center pt-2">
                    <Col>
                      <h2 className="text-center text-green-dark">{space.name}</h2>
                      <h4 className="text-center text-green-medium">{space.description}</h4>
                    </Col>
                    <Col>
                      <Img fluid={space.image.childImageSharp.fluid} alt={space.name}/>
                    </Col>
                  </Row>
                  )
                }
              })
            }
          </Container>
        </Container>
      </Layout>
    )
  }  

export default Page

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
          frontmatter {
            spaces {
              name
              description
              image {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 75) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
    }`
